import {Component, Input, OnInit} from '@angular/core';
import {DeckType} from '../../shared/card-deck';
import {CardPattern} from '../../shared/card-pattern';
import {CardStoreService} from '../../shared/card-store.service';
import {Observable} from 'rxjs';
import {CardPatternObstacleConnection} from '../../shared/card-pattern-obstacle-connection';
import {CardPatternObstacleItem} from '../../shared/card-pattern-obstacle-item';
import {CardWebService} from '../../shared/card-web.service';
import {EditorService} from '../shared/editor.service';

@Component({
  selector: 'em-card-pattern-small',
  templateUrl: './card-pattern-small.component.html',
  styleUrls: ['./card-pattern-small.component.css']
})
export class CardPatternSmallComponent implements OnInit {

  @Input() cardPatternName: string;
  @Input() cardDeckId: number;
  @Input() cardPatternId: number;
  @Input() cardDeckType: DeckType;


  @Input() sourcePatternName: string;
  @Input() sourcePatternId: number;
  @Input() sourceDeckType: DeckType;

  @Input() customConnection: boolean;

  customObstacleConnection: CardPatternObstacleConnection;
  customObstacleItem: CardPatternObstacleItem;
  cardPattern$: Observable<CardPattern>;

  cannotConnect = false;
  sourceIsNotInCardWeb = false;
  noFreeConnector = false;
  targetNotInReach = false;

  constructor(
    private cs: CardStoreService,
    private cw: CardWebService,
    private es: EditorService
  ) {
  }

  ngOnInit(): void {
    this.es.getConncectionParams().subscribe(response => {
      this.cardPattern$ = this.cs.getPatternCardWithCell(this.cardDeckId, this.cardPatternId, this.cardDeckType);
      if (this.customConnection) {
        this.customObstacleItem = {
          id: response.id * 10 + this.cardPatternId,
          type: this.cardDeckType,
          cardNumber: null,
          question: null,
          description: null,
          solution: null,
          patternDescription: null
        };

        this.customObstacleConnection = {
          cardDeckId: this.cardDeckId,
          cardDeckName: null,
          cardDeckType: this.sourceDeckType,
          patternId: response.id,
          patternCardNumber: null,
          patternName: this.sourcePatternName,
          patternSummary: null,
          patternImageUrl: null,
          obstaclePatternId: this.cardPatternId,
          obstaclePatternType: this.cardDeckType,
          obstaclePatternName: this.cardPatternName,
          obstacleItems: null
        };

        // check if card has any restrictions for connections and display the corresponding error message
        if (!this.cw.hasCardWebElement(this.sourcePatternId)) {
          this.cannotConnect = true;
          this.sourceIsNotInCardWeb = true;
        } else if (!this.cw.hasFreeConnector(this.sourcePatternId, this.cardPatternId)) {
          this.cannotConnect = true;
          this.noFreeConnector = true;
        } else if (this.cw.hasCardWebElement(this.cardPatternId)
          && !this.cw.isTargetInReach(this.sourcePatternId, this.cardPatternId)) {
          this.targetNotInReach = true;
          this.cannotConnect = true;
        } else {
          // reset states, because they might still be true from previous views
          this.cannotConnect = false;
          this.noFreeConnector = false;
          this.sourceIsNotInCardWeb = false;
          this.targetNotInReach = false;
        }
      }
    });
  }

}
